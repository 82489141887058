fieldset {
  margin: 15px 0;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.flexField {
  margin-top: 10px;
  width: auto;
}

.flexOne {
  width: 100% !important;
}

li {
  list-style: none;
  padding: 0;
}

input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
}

label {
  cursor: pointer;
  display: block;
  border: 2pt solid white;
  padding: 5px;
  text-align: center;
  position: relative;
}

.quantity {
  visibility: hidden;
}

input[type="checkbox"]:checked + label {
  border: 2pt solid #fd8204;
}

input[type="checkbox"]:focus + label {
  outline: rgb(74, 189, 216, 0.5) auto 5px;
}

input[type="checkbox"]:checked ~ div {
  visibility: visible !important;
}

.disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(116, 116, 116, 0.5);
}

img {
  display: block;
  width: 75px;
  margin: auto;
}

@media (min-width: 500px) {
  .flexField {
    width: 100%;
  }

  li {
    padding: 5px;
  }
}

@media (min-width: 700px) {
  .flexField {
    width: 45%;
  }
}
