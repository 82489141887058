.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

.admin {
    position: absolute;
    right: 10px;
    top: 36px;
    box-sizing: border-box;
    height: 36px;
    width: 150px;
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    text-shadow:
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;
    background-color: transparent;
}
  
.admin:hover, .admin:focus {
    background-color: #fd8204;
    text-shadow: none;
}

.bm-burger-bars {
    background: white;
    opacity: 1;
}

.bm-burger-bars-hover {
    background: rgb(253, 130, 4);
    opacity: 1 !important;
    transition: all 0.33s ease;
}
   
.bm-cross-button {
    height: 24px;
    width: 24px;
}
   
.bm-cross {
    background: #bdc3c7;
}
   
.bm-menu-wrap {
    position: absolute;
    height: 100%;
}
   
.bm-menu {
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}
   
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    text-align: left;
  }
   
.bm-item {
    display: inline-block;
    color: #fd8204;
    border-bottom: 1px solid #edeceb;
    padding: 20px 20px 20px 0;
}

.bm-item:hover {
    color: #feb061
}
   
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}