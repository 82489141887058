.checkForm * {
  font-size: 16pt;
}

.checkForm {
    padding-top: 50px;
    max-width: 700px;
    margin: auto;
}

.namesFieldset {
  padding: 15px 20px 20px;
}

select {
  font-size: 14px;
  min-width: 50px;
}

.names {
  min-width: 125px;
}

legend {
  font-size: 18px;
}

.fieldsetFlex {
  display: block;
}

.names:invalid {
  color: grey;
}

.emptyForm {
  padding: 5px 15px 15px;
  border: 2px groove #c0c0c0;
  width: auto;
}

.checkOutLink {
  width: 200px;
}

@media (min-width: 500px) {
  .fieldsetFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .checkForm * {
    font-size: 12pt;
  }

  .emptyForm {
    width: 100%;
  }

}