p, li {
    font-size: 16pt;
  }

.checkedOutItem > p {
    margin-top: 0;
    text-align: left;
    padding: 0 10px;
}

.viewListUser {
    margin: 0;
}

.viewList {
    list-style: none;
    text-align: left;
    margin: 5px 0 15px;
}

@media (min-width: 500px) {
    p, li {
        font-size: 12pt;
      }
}