.dashboard {
    text-align: left;
    padding-left: 10%;
}

.accordion {
    max-width: 600px;
    margin: auto;
}

.accordion__item {
    cursor: pointer;
}

.viewItems {
    background-color: #EDECEB;
    border: 2pt solid #fd8204;
    padding-top: 15px;
}

.dashboard_heading {
    font-size: 16pt;
    border: 2pt solid #fd8204;
    padding: 10px;
    margin-top:10px;
    text-align: left;
    position: relative;
}

.dashboard_heading:hover, .dashboard_heading:focus {
    background-color: #fd8204;
    color: white;
    transition: all 0.33s ease;
}

.accordion__button {
    outline-offset: 5px;
}

.angleIcon {
    position: absolute;
    right: 5%;
}

#rotateUp {
    transition: transform 0.33s ease;
    transform: rotate(180deg);
}

#rotateDown {
    transition: transform 0.33s ease;
    transform: rotate(0deg)
}

@media (min-width: 500px) {
    .dashboard_heading {
        font-size: 18px;
      }
}
