.userAccordion * {
    font-size: 16pt;
  }

.user {
    display: inline;
    margin: 10px;
}

input[id="addUser"] {
    height: 24px;
    margin-top: 20px;
}

.accordion2 {
    font-size: 16pt;
    font-weight: normal;
    background-color: #EDECEB;
    border: 2pt solid #fd8204;
    padding: 10px;
    position: relative;
}

.accordion2:hover {
    background-color: #fd8204;
    color: white;
    transition: all 0.33s ease;
}

select[id="user"] {
    min-width: 100px;
    margin-top: 20px;
}

select:invalid {
    color: grey;
}

.plus {
    font-size: 12pt !important;
}

@media (min-width: 500px) {
    .userAccordion * {
        font-size: 12pt;
      }

    input[id="addUser"] {
        height: 24px;
        margin-bottom: 20px;
    }

    .user {
        display: inline;
        margin-left: 10px;
    }
}