.links {
    padding: 10px;
}
  
div > .confirmation {
    border: 5px solid #4ABDD8;
    background-color: #D41A68;
    padding: 10px;
    color: white;
}

.info {
  max-width: 300px;
  margin: auto;
}

.info h3 {
  margin-bottom: 5px;
}

.bulletpoint {
  max-width: 175px;
  margin: auto;
}

.bulletpoint li{
  list-style: circle;
  text-align: left;
}