body {
  text-align: center;
  background-color: white;
  color: #333333;
  font-family: "Roboto", sans-serif;
}

.App_header {
  position: absolute;
  background: url("../navImage.jpeg") no-repeat;
  background-position-y: 15%;
  background-size: cover;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 1;
}

.App_main {
  position: relative;
  top: 109px;
  padding: 90px 10%;
}

:focus {
  outline: 5px auto rgb(74, 189, 216, 0.5);
}

.title_header {
  position: absolute;
  left: 0;
  top: 70px;
  background-color: rgb(253, 130, 4, 0.85);
  height: 80px;
  width: 50%;
  display: flex;
}

.title {
  margin: auto;
  font-weight: normal;
  font-size: 90%;
  align-items: center;
}

a {
  text-decoration: none;
  color: white;
}

button {
  color: white;
  border: 2pt solid white;
  background-color: #fd8204;
  cursor: pointer;
  width: 150px;
  font-size: 16pt;
  padding: 10px;
  margin: 10px auto 0;
  transition: all 0.33s ease;
}

button:hover,
button:focus {
  background-color: #ffb718;
}

@media (min-width: 500px) {
  .title_header {
    width: 300px;
    height: 100px;
  }

  .App_header {
    height: 200px;
  }

  .App_main {
    top: 129px;
  }

  .title {
    font-size: 45px;
  }

  button {
    font-size: 12pt;
    width: 120px;
  }
}
