.inventoryAccordion * {
    font-size: 16pt;
  }

.item {
    margin: 15px
}

.addForm {
    display: inline;
}

.addFormContainer {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: auto;
}

.addFormContainer input {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
}

table label {
    text-align: right;
}

table input, table select {
    text-align: left;
}

select[id="category"] {
    width: 100%;
}

select[id="item"] {
    min-width: 135px;
    margin-top: 15px;
}

.left {
    text-align: left;
}

@media (min-width: 500px) {
    .inventoryAccordion * {
        font-size: 12pt;
      }
    
    select[id="item"] {
        margin-top: 0px;
    }

    .left {
        text-align: center;
    }
}