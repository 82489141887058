button {
  padding: 5px;
  font-size: 10pt;
  cursor: pointer;
}

.formHeader {
  margin: 0;
  padding: 10px;
  background-color: rgb(253, 130, 4, 0.85);
  color: white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 70px;
  font-weight: normal;
}

div > .confirmation {
  border: 5px solid #4ABDD8;
  background-color: #D41A68;
  padding: 10px;
  color: white;
}